import "core-js/stable";
import "regenerator-runtime/runtime";

import "./js/config.js";

// Load in vendor scripts (jquery loaded by Webpack directly);
import "bootstrap";

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';


// Load in styles
import "./scss/styles.scss";


// Conditional dynamic imports

// Webpack will store those modules in separate JS files and
// load them on demand, keeping payload down.


// Load in custom JS modules required on initial page load

import('./js/viewport-size');

if (document.querySelector('body.site-layout') != null) {
    import('./js/scrolling');
    import('./js/keyboard-accessibility-check');
}

if (document.querySelector('.header--content--menu') != null) {
    import('./js/main-navigation');
}

if (document.querySelector('#header-search') != null) {
    import('./js/header-search');
}

if (document.querySelector('#checkout-time-remaining:not(.complete)') != null) {
    import('./js/checkout-time-remaining');
}

if (document.querySelector('form') != null) {
    import('./js/form-validation');
}

if (document.querySelector('.emergency-message') != null) {
    import('./js/alert-message');
}

if (document.querySelector('#account-logout-form') != null) {
    import('./js/logout-form');
}


// Seat Selection Service
if (document.querySelector('.js-seating-plan') != null) {
    import('./js/seatSelection');
}

// Load in custom JS modules on initial page load and when DOM is updated

const addDataJsParsed = (elements, tag = '') => {
    elements.forEach(x => {
        x.setAttribute(`data-js-parsed--${tag}`,'');
    });
}

const jsModuleCheck = () => {

    let testQuery = null;

    // smart-bootstrap-tabs
    testQuery = document.querySelector('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
        addDataJsParsed(thisDomQuery, "smart-bootstrap-tabs");
        import('./js/smart-bootstrap-tabs').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // defer-iframe-content.js
    testQuery = document.querySelector('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
        addDataJsParsed(thisDomQuery, "defer-iframe-content");
        import('./js/defer-iframe-content').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // height matching
    testQuery = document.querySelector('*[data-hm-group]:not([data-js-parsed--height-match])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-hm-group]:not([data-js-parsed--height-match])');
        addDataJsParsed(thisDomQuery, "height-match");
        import('./js/height-match').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hunt-watch
    testQuery = document.querySelector('.hunt-watch:not([data-js-parsed--hunt-watch])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hunt-watch:not([data-js-parsed--hunt-watch])');
        addDataJsParsed(thisDomQuery, "hunt-watch");
        import('./js/hunt-watch').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - vimeo
    testQuery = document.querySelector('.hero--video.vimeo:not([data-js-parsed--vimeo])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.vimeo:not([data-js-parsed--vimeo])');
        addDataJsParsed(thisDomQuery, "vimeo");
        import('./js/hero-video-vimeo').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - youtube
    testQuery = document.querySelector('.hero--video.youtube:not([data-js-parsed--youtube])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.youtube:not([data-js-parsed--youtube])');
        addDataJsParsed(thisDomQuery, "youtube");
        import('./js/hero-video-youtube').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero clickable
    testQuery = document.querySelector('.hero--layout.clickable:not([data-js-parsed--hero-clickable])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--layout.clickable:not([data-js-parsed--hero-clickable])');
        addDataJsParsed(thisDomQuery, "hero-clickable");
        import('./js/hero-clickable').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // non-lazyload img-load-watcher
    testQuery = document.querySelector('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
        addDataJsParsed(thisDomQuery, "img-load-watcher");
        import('./js/img-load-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // image-loader-group
    testQuery = document.querySelector('.image-loader-group:not([data-js-parsed--image-loader-group])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.image-loader-group:not([data-js-parsed--image-loader-group])');
        addDataJsParsed(thisDomQuery, "image-loader-group");
        import('./js/image-loader-group').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // countdown
    testQuery = document.querySelector('.countdown:not([data-js-parsed--countdown])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.countdown:not([data-js-parsed--countdown])');
        addDataJsParsed(thisDomQuery, "countdown");
        import('./modules/countdown/countdown.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // lightboxes (PhotoSwipe v5 - galleries and single images)
    testQuery = document.querySelector('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
        addDataJsParsed(thisDomQuery, "lightbox");
        import('./modules/lightboxes-galleries/lightboxes.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // data-sizes-js-fix (fixes lazysizes issue)
    testQuery = document.querySelector('*[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
        addDataJsParsed(thisDomQuery, "data-sizes-js-fix");
        import('./js/data-sizes-js-fix').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // testimonials-carousel
    testQuery = document.querySelector('.testimonials-carousel:not([data-js-parsed--testimonials-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.testimonials-carousel:not([data-js-parsed--testimonials-carousel])');
        addDataJsParsed(thisDomQuery, "testimonials-carousel");
        import('./modules/testimonials-carousel/testimonials-carousel').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // stages-layout
    testQuery = document.querySelector('.stages-layout:not([data-js-parsed--stages-layout])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.stages-layout:not([data-js-parsed--stages-layout])');
        addDataJsParsed(thisDomQuery, "stages-layout");
        import('./js/stages-layout').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // responsive nav bar
    testQuery = document.querySelector('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
        addDataJsParsed(thisDomQuery, "responsive-nav-bar");
        import('./modules/responsive-nav-bar/responsive-nav-bar').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // filter-listing (live and static)
    testQuery = document.querySelector('.filter-listing:not([data-js-parsed--filter-listing])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.filter-listing:not([data-js-parsed--filter-listing])');
        addDataJsParsed(thisDomQuery, "filter-listing");
        import('./js/filter-listing').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // responsive table
    testQuery = document.querySelector('.responsive-table:not([data-js-parsed--responsive-table])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-table:not([data-js-parsed--responsive-table])');
        addDataJsParsed(thisDomQuery, "responsive-table");
        import('./modules/responsive-table/responsive-table');
    }

    // help search form
    testQuery = document.querySelector('.help-search-form:not([data-js-parsed--help-search-form])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.help-search-form:not([data-js-parsed--help-search-form])');
        addDataJsParsed(thisDomQuery, "help-search-form");
        import('./js/help-search-form').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // tripadvisor icon shiv
    if (document.querySelector('.fa-tripadvisor') != null) {
        import('./js/tripadvisor-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tripadvisor'));
        });
    }

    // tiktok icon shiv
    if (document.querySelector('.fa-tiktok') != null) {
        import('./js/tiktok-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tiktok'));
        });
    }

    // width-watcher
    testQuery = document.querySelector('*[data-width-watcher]:not([data-js-parsed--width-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-width-watcher]:not([data-js-parsed--width-watcher])');
        addDataJsParsed(thisDomQuery, "width-watcher");
        import('./js/width-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // package-options-modal
    testQuery = document.querySelector('.listing-item[data-package-options-modal]:not([data-js-parsed--package-options-moda])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.listing-item[data-package-options-modal]:not([data-js-parsed--package-options-moda])');
        addDataJsParsed(thisDomQuery, "package-options-modal");
        import('./js/package-options-modal').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // form number control
    testQuery = document.querySelector('.form-number-control:not(.readonly):not([data-js-parsed--form-number-control])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.form-number-control:not(.readonly):not([data-js-parsed--form-number-control])');
        addDataJsParsed(thisDomQuery, "form-number-control");
        import('./js/form-number-control').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // form-check enable target
    testQuery = document.querySelector('input[data-form-check-enable-target]:not([data-js-parsed--form-check-enable-target])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('input[data-form-check-enable-target]:not([data-js-parsed--form-check-enable-target])');
        addDataJsParsed(thisDomQuery, "form-check-enable-target");
        import('./js/form-check-enable-target').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // product quantities (enable/disabled btn based on total quantity)
    testQuery = document.querySelector('.product-quantities:not([data-js-parsed--product-quantities])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.product-quantities:not([data-js-parsed--product-quantities])');
        addDataJsParsed(thisDomQuery, "product-quantities");
        import('./js/product-quantities').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // product-gallery
    testQuery = document.querySelector('.product-gallery:not([data-js-parsed--product-gallery])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.product-gallery:not([data-js-parsed--product-gallery])');
        addDataJsParsed(thisDomQuery, "product-gallery");
        import('./js/product-gallery').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // product-gallery-carousel
    testQuery = document.querySelector('.product-gallery-carousel:not([data-js-parsed--product-gallery-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.product-gallery-carousel:not([data-js-parsed--product-gallery-carousel])');
        addDataJsParsed(thisDomQuery, "product-gallery-carousel");
        import('./js/product-gallery-carousel').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // membership-details-group
    testQuery = document.querySelector('.membership-details-group:not([data-js-parsed--membership-details-group])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.membership-details-group:not([data-js-parsed--membership-details-group])');
        addDataJsParsed(thisDomQuery, "membership-details-group");
        import('./js/membership-details-group').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // membership-details-seats-group
    testQuery = document.querySelector('.membership-details-seats-group:not([data-js-parsed--membership-details-seats-group])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.membership-details-seats-group:not([data-js-parsed--membership-details-seats-group])');
        addDataJsParsed(thisDomQuery, "membership-details-seats-group");
        import('./js/membership-details-seats-group').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // checkbox-as-radio-list
    testQuery = document.querySelector('.checkbox-as-radio-list:not([data-js-parsed--checkbox-as-radio-list])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.checkbox-as-radio-list:not([data-js-parsed--checkbox-as-radio-list])');
        addDataJsParsed(thisDomQuery, "checkbox-as-radio-list");
        import('./js/checkbox-as-radio-list.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // radio-collapse-control
    testQuery = document.querySelector('.radio-collapse-control:not([data-js-parsed--radio-collapse-control])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.radio-collapse-control:not([data-js-parsed--radio-collapse-control])');
        addDataJsParsed(thisDomQuery, "radio-collapse-control");
        import('./js/radio-collapse-control').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // FRIAT Membership Lookup component
    testQuery = document.querySelector('.friat-members-lookup:not([data-js-parsed--friat-members-lookup])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.friat-members-lookup:not([data-js-parsed--friat-members-lookup])');
        addDataJsParsed(thisDomQuery, "friat-members-lookup");
        import('./js/friat-members-lookup').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // Basket Service
    testQuery = document.querySelector('.js-basket-tickets:not([data-js-parsed--basket-servce-api])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.js-basket-tickets:not([data-js-parsed--basket-servce-api])');
        addDataJsParsed(thisDomQuery, "basket-servce-api");
        import('./js/basketServiceApi').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    //Order history filter
    testQuery = document.querySelector('.account--orders-listing:not([data-js-parsed--account--orders-listing])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.account--orders-listing:not([data-js-parsed--account--orders-listing])');
        addDataJsParsed(thisDomQuery, "account--orders-listing");
        import('./js/order-history-filter').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // internal-packages-listing
    testQuery = document.querySelector('.internal-packages-listing:not([data-js-parsed--internal-packages-listing])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.internal-packages-listing:not([data-js-parsed--internal-packages-listing])');
        addDataJsParsed(thisDomQuery, "internal-packages-listing");
        import('./js/internal-packages-listing').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

};

//// create a mutation observer to watch for dynamically added HTML

let checkRequested = false, checkTimeout = null;

// run a check on page load
jsModuleCheck();

// Select the node that will be observed for mutations
const targetNode = document.querySelector('body');
if(targetNode != null) {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = function(mutationsList) {
        // Use traditional 'for loops' for IE 11
        let checkNeeded = false;
        for(const mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                checkNeeded = true;
            }
        }
        if(checkNeeded && !checkRequested) {
            checkRequested = true;
            clearTimeout(checkTimeout);
            checkTimeout = setTimeout(() => {
                jsModuleCheck();
                checkRequested = false;
            }, 100);
        }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    setTimeout(() => {
        // do another check now
        jsModuleCheck();
        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    }, 3000);
}


const orderProcessing = document.querySelector('.js-order-processing');

if (orderProcessing) {
    setInterval(() => {
        fetch(`/Umbraco/Surface/OrderStatus/Get/?oid=${orderProcessing.dataset.id}&sid=${orderProcessing.dataset.sessionId}`, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(response => {
                if (!response.processing) {
                    window.location = response.url
                }
                else {
                    document.querySelector('.js-processing-message').innerHTML = response.message;
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, 2000);
}
